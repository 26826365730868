<template>
  <div class="flvplayer">
    <el-card class="centeredVideo" :body-style="{ padding: '0px' }" v-bind:class="{ 'el-card-darkmode': DarkMode }">
      <video
          v-show="videoShow"
          id="videoElement"
          controls
          autoplay
          width="1280px"
          height="720px"
      >
        你的浏览器不支持HTML5视频，换个浏览器试试吧w
      </video>
      <transition name="el-fade-in">
        <div class="imageBox" v-show="!videoShow" rel="preload">
          <el-image
              class="videoImage"
              fit="cover"
              src="/img/77777498_p0.jpg"
          ></el-image>
          <p class="videoNote">少女祈祷中...</p>
        </div>
      </transition>
    </el-card>
  </div>
</template>

<script>
import flvjs from "flv.js";

export default {
  name: "FlvPlayer",
  methods: {
    pull() {
      eval("console.clear()");
    },
  },
  data() {
    return {
      videoShow: true,
    };
  },
  mounted: function () {
    this.timer = setInterval(this.pull, 200);
    let b64 = require("js-base64").Base64;
    if (flvjs.isSupported()) {
      var videoElement = document.getElementById("videoElement");
      var flvPlayer = flvjs.createPlayer({
        type: "flv",
        "isLive": true,
        url: b64.decode(
            "aHR0cHM6Ly93d3cuZ29vZ2xlLmNvbS8="
        ),
      });
      flvPlayer.attachMediaElement(videoElement);
      flvPlayer.on(flvjs.Events.ERROR, (errorInfo) => {
        if (errorInfo.code === 404) {
          this.$message({
            showClose: true,
            message: "还没有开播w",
            type: "warning",
          });
        } else {
          this.$message({
            showClose: true,
            message: "发生错误：已经结束咧！(´；ω；`)",
            type: "error",
          });
        }
        flvPlayer.unload();
        this.videoShow = false;
      });
      flvPlayer.load();
    }
  },
  props: ['DarkMode'],
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.imageBox {
  position: relative;
}

.centeredVideo {
  width: 1280px;
  height: 720px;
}

.videoImage {
  width: 100%;
  height: 100%;
}

.videoNote {
  position: absolute;
  font-size: 36px;
  top: 610px;
  left: 30px;
  color: white;
  text-shadow: 2px 2px 10px #202020;
}

.el-card-darkmode {
  background-color: #252526;
  border: 1px solid #252526;
  box-shadow: 0 2px 12px 0 rgb(255 255 255 / 25%);
}
</style>
