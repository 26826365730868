<template>
  <div id="app">
    <el-row>
      <el-col :span="24">
        <h1 class="title-box" v-bind:class="{ 'title-box-darkmode': darkMode }">
          <span class="logo-text">⚓</span><span class="red-text">Aqua</span><span class="blue-text">Hub</span>
        </h1>
        <p class="black-text" v-bind:class="{ 'black-text-darkmode': darkMode }">
          一个安心看夸的小站w：手机端横屏或全屏体验更佳『<span style="text-decoration:underline;">请不要对聊天室投喂付费礼物，阿夸无法收到，我们也并不需要，大家的好意我们心领了</span>』（注意：iOS由于不支持flv流，所以会出现无法播放的情况）
        </p>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="20">
        <FlvPlayer :DarkMode="darkMode"></FlvPlayer>
      </el-col>
      <el-col :span="4">
        <BiliChat :DarkMode="darkMode"></BiliChat>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="12">
        <div class="note-left">
          <p class="black-text" v-bind:class="{ 'black-text-darkmode': darkMode }">开发者: <el-link href="https://space.bilibili.com/13735938" :underline="false" target="_blank" type="primary">小陈菌</el-link>（<el-link href="https://twitter.com/ciisaichan" :underline="false" target="_blank" type="primary">@ciisaichan</el-link>）</p>
          <p class="black-text" v-bind:class="{ 'black-text-darkmode': darkMode }">服务器: <el-link href="https://space.bilibili.com/9535079" :underline="false" target="_blank" type="primary">因扎因</el-link> （<el-link href="https://twitter.com/yocdomer" :underline="false" target="_blank" type="primary">@yocdomer</el-link>）</p>
          <p class="black-text" v-bind:class="{ 'black-text-darkmode': darkMode }">聊天室: <el-link href="https://space.bilibili.com/1423737" :underline="false" target="_blank" type="primary">唯の小诺</el-link> （<el-link href="https://twitter.com/V1_Xiaonuo" :underline="false" target="_blank" type="primary">@V1_Xiaonuo</el-link>）</p>
        </div>
      </el-col>
      <el-col :span="12">
        <div class="note-right">
          <div class="anti-text-box" @mouseover="MouseoverText" @mouseleave="MouseleaveText">
            <p class="black-text" v-bind:class="{ 'black-text-darkmode': darkMode }">{{ antiTextx }}</p>
          </div>
          <el-button type="primary" size="small" style="float: right;" @click="switchDarkMode">{{ darkMode ? '明亮模式':'黑暗模式' }}</el-button>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import FlvPlayer from "./components/FlvPlayer";
import BiliChat from "./components/BiliChat";

export default {
  name: "App",
  components: {
    FlvPlayer,
    BiliChat,
  },
  data() {
    return {
      darkMode: false,
      antiTextx: '湊あくあ 好き！',
    };
  },
  methods: {
    switchDarkMode() {
      this.darkMode = !this.darkMode;
      if(this.darkMode){
        document.getElementsByTagName("body")[0].setAttribute("style","background-color:#1E1E1E");
      }else{
        document.getElementsByTagName("body")[0].setAttribute("style","background-color:#F4F4F5");
      }
    },
    MouseoverText() {
      this.antiTextx = '" Mea x Aqua ! "';
    },
    MouseleaveText() {
      this.antiTextx = '" debu debu aqua debuaqua ~ "';
    },
  },
  created() {
    this.MouseleaveText();
    var now_hours = new Date().getHours();
    if (now_hours >= 19 || now_hours <= 6) {
      this.switchDarkMode();
    }

    this.$notify({
      title: '公告',
      message: '此网站目前已停止运营，仅保留界面作为纪念，怀念和大家一起在这里看夸聊天的氛围和时光，祝各位前程似锦，有缘再见w',
      duration: 0
    });
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  width: 1550px;
  margin-left: auto;
  margin-right: auto;
}
.note-right {
  float: right;
  font-size: 13px;
  line-height: 5px;
  margin-top: 5px;
}
.note-left {
  float: left;
  font-size: 13px;
  line-height: 5px;
  margin-top: 5px;
}

.red-text {
  color: #FFF;
  font-size: 36px;
  margin-left: 3px;
}

.blue-text {
  color: #000;
  border-radius: 4px;
  background-color: #FF9900;
  font-size: 36px;
  margin-left: 8px;
}

.black-text{
  text-shadow: 2px 2px 6px rgba(0, 0, 0, 0.8);
  white-space: pre;
}

.black-text-darkmode {
  color: rgb(255, 255, 255);
  text-shadow: 2px 2px 6px rgba(255, 255, 255, 0.8);
  transition: .3s;
}

.logo-text {
  font-size: 36px;
}

.title-box {
  background-color: #000;
  width: 240px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content:center;
  border-radius: 4px;
  margin-top: 15px;
  margin-bottom: 15px;
  transition: .3s;
}

.title-box-darkmode {
  background-color: rgba(255, 255, 255, 0);
  transition: .3s;
}

.anti-text-box {
  width: 220px;
  height: 20px;
  text-align: right;
}
</style>
